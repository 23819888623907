import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "timi-dashboard-container" }
const _hoisted_2 = { class: "date-slider" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = {
  key: 0,
  class: "statistic-wrapper"
}
const _hoisted_7 = { class: "statistic-item" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "statistic-item" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "statistic-item" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "actions" }
const _hoisted_14 = { class: "statistic-item" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "actions" }
const _hoisted_17 = {
  key: 0,
  class: "statistic-item"
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "list-workbreaks" }
const _hoisted_22 = { class: "workbreak-from" }
const _hoisted_23 = { class: "workbreak-to" }
const _hoisted_24 = { class: "workbreak-to" }
const _hoisted_25 = { class: "actions" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-6" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_PresenceForm = _resolveComponent("PresenceForm")!
  const _component_Card = _resolveComponent("Card")!
  const _component_WorkbreakForm = _resolveComponent("WorkbreakForm")!
  const _component_TimeEntryForm = _resolveComponent("TimeEntryForm")!
  const _component_TimeEntryCard = _resolveComponent("TimeEntryCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("h2", null, _toDisplayString(_ctx.employee.number) + ": " + _toDisplayString(_ctx.employee.firstName) + " " + _toDisplayString(_ctx.employee.lastName), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconButton, {
        icon: _ctx.store.getters.svgIcons.arrowbackward,
        class: "",
        onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSwitchDay(-1)))
      }, null, 8, ["icon"]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.displayActiveDay), 1),
      _createVNode(_component_IconButton, {
        icon: _ctx.store.getters.svgIcons.arrowforward,
        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSwitchDay(1)))
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.presenceFormVisible)
          ? (_openBlock(), _createBlock(_component_Card, {
              key: 0,
              title: _ctx.$t('headline.editPresence'),
              class: "transparent border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PresenceForm, {
                  presence: _ctx.editPresence,
                  onCancel: _ctx.onPresenceEditFormCancel,
                  onUpdate: _ctx.onPresenceEditFormUpdate
                }, null, 8, ["presence", "onCancel", "onUpdate"])
              ]),
              _: 1
            }, 8, ["title"]))
          : (_openBlock(), _createBlock(_component_Card, {
              key: 1,
              title: _ctx.$t('day.dailyStatistic')
            }, {
              default: _withCtx(() => [
                (_ctx.dataLoaded.presence)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('day.presenceTime')), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.convertSecondsToTime(_ctx.presenceTime)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('day.workingtimeSUM')), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.convertSecondsToTime(_ctx.timeSum)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('day.comeAt')), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.activePresence.come ? _ctx.convertTimeStampToDate(_ctx.activePresence.come) + ', ' + _ctx.convertToTime(_ctx.activePresence.come) + " " + _ctx.$t('label.oClock') : "--"), 1),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_IconButton, {
                            icon: _ctx.store.getters.svgIcons.pencil,
                            onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPresenceForm(_ctx.activePresence)))
                          }, null, 8, ["icon"]),
                          _createVNode(_component_IconButton, {
                            icon: _ctx.store.getters.svgIcons.delete,
                            onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onRemovePresence(_ctx.activePresence, 'come')))
                          }, null, 8, ["icon"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('day.goAt')), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.activePresence.go ? _ctx.convertTimeStampToDate(_ctx.activePresence.go) + ', ' + _ctx.convertToTime(_ctx.activePresence.go) + " " + _ctx.$t('label.oClock') : "--"), 1),
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_IconButton, {
                            icon: _ctx.store.getters.svgIcons.pencil,
                            onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onPresenceForm(_ctx.activePresence)))
                          }, null, 8, ["icon"]),
                          _createVNode(_component_IconButton, {
                            icon: _ctx.store.getters.svgIcons.delete,
                            onOnClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onRemovePresence(_ctx.activePresence, 'go')))
                          }, null, 8, ["icon"])
                        ])
                      ]),
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('day.lastInteractivity')) + ":", 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.latestInteraction + " " + _ctx.$t('label.oClock')), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "svgicon svgicon--loader",
                      innerHTML: _ctx.store.getters.svgIcons.loader
                    }, null, 8, _hoisted_18))
              ]),
              _: 1
            }, 8, ["title"]))
      ]),
      _createElementVNode("div", _hoisted_19, [
        (_ctx.dataLoaded.workbreaks)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              (_ctx.workbreakFormVisible)
                ? (_openBlock(), _createBlock(_component_Card, {
                    key: 0,
                    title: _ctx.$t('headline.editWorkbreak'),
                    class: "transparent border"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_WorkbreakForm, {
                        workbreak: _ctx.editWorkbreak,
                        onCancel: _ctx.onWorkbreakEditFormCancel,
                        onUpdate: _ctx.onWorkbreakEditFormUpdate
                      }, null, 8, ["workbreak", "onCancel", "onUpdate"])
                    ]),
                    _: 1
                  }, 8, ["title"]))
                : (_openBlock(), _createBlock(_component_Card, {
                    key: 1,
                    title: _ctx.$t('day.workbreaks')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_21, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workbreaks, (workbreak) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: workbreak.uid,
                            class: "list-entry-workbreak"
                          }, [
                            _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('label.workbreakStart')) + " " + _toDisplayString(_ctx.convertTimeStampToTime(workbreak.start)), 1),
                            _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t('label.workbreakStop')) + " " + _toDisplayString((workbreak.stop ? _ctx.convertTimeStampToTime(workbreak.stop) : '-')), 1),
                            _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('label.workbreakPaused')) + " " + _toDisplayString((workbreak.getTimePaused() ? _ctx.convertSeconds(workbreak.getTimePaused()) : '-')), 1),
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(_component_IconButton, {
                                icon: _ctx.store.getters.svgIcons.pencil,
                                onOnClick: ($event: any) => (_ctx.onWorkbreakForm(workbreak))
                              }, null, 8, ["icon", "onOnClick"]),
                              _createVNode(_component_IconButton, {
                                icon: _ctx.store.getters.svgIcons.delete,
                                onOnClick: ($event: any) => (_ctx.onRemoveWorkbreak(workbreak))
                              }, null, 8, ["icon", "onOnClick"])
                            ])
                          ]))
                        }), 128)),
                        (!_ctx.workbreaks.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.workbreakNone')), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 8, ["title"]))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        (_ctx.dataLoaded.timeentries)
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              (_ctx.timeEntryFormVisible)
                ? (_openBlock(), _createBlock(_component_Card, {
                    key: 0,
                    title: _ctx.$t('headline.editTimeentry'),
                    class: "transparent border"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TimeEntryForm, {
                        entry: _ctx.editEntry,
                        onCancel: _ctx.onTimeentryEditFormCancel,
                        onUpdate: _ctx.onTimeentryEditFormUpdate
                      }, null, 8, ["entry", "onCancel", "onUpdate"])
                    ]),
                    _: 1
                  }, 8, ["title"]))
                : (_openBlock(), _createBlock(_component_Card, {
                    key: 1,
                    title: _ctx.$t('dashboard.activities')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeEntries, (entry) => {
                        return (_openBlock(), _createBlock(_component_TimeEntryCard, {
                          key: entry.uid,
                          entry: entry,
                          mode: _ctx.userMode,
                          onDelete: _ctx.onDeleteTimeEntry,
                          onEdit: _ctx.onEditTimeEntry
                        }, null, 8, ["entry", "mode", "onDelete", "onEdit"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["title"]))
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "svgicon svgicon--loader",
              innerHTML: _ctx.store.getters.svgIcons.loader
            }, null, 8, _hoisted_30))
      ])
    ])
  ]))
}